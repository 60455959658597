import { BrowserRouter } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as auth from "../store/Actions/Auth.action";
import AppLoading from '../components/AppLoading';

/**
 * Renders routes depending on Authenticated or Anonymous users
 * @component Routes
 */
const Routes = ({ isLoggedIn, isRefreshTokenChecked, refreshTokenValid }: { isLoggedIn: boolean, isRefreshTokenChecked: boolean, refreshTokenValid: Function }) => {
  refreshTokenValid();
  return (
    <>
      {!isRefreshTokenChecked ? <AppLoading /> : 
      <BrowserRouter>{isLoggedIn ? <PrivateRoutes key={0} /> : <PublicRoutes key={1} />}</BrowserRouter>
  }
    </>
  );
};

const mapStateToProps = (state: any) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isRefreshTokenChecked: state.auth.isRefreshTokenChecked,
});
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  refreshTokenValid: async () => dispatch(auth.refreshTokenValid()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Routes);
