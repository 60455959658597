import { CLEAR_TOAST, TOAST_MESSAGE } from '../Constants';

const initialState = {
  text: '',
  type: '',
  head: '',
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOAST_MESSAGE:
      return {
        ...state,
        text: action.payload.text,
        type: action.payload.type,
        head: action.payload.head,
      };
    case CLEAR_TOAST:
      return {
        ...state,
        text: '',
        type: '',
        head: '',
      };
    default:
      return state;
  }
};

export default reducer;
