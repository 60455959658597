import {legacy_createStore as createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';

import rootReducer from './Reducers/index';
const logger = createLogger({
  collapsed: true,
  diff: true,
});

const middleWares = [thunk, logger];
const store = createStore(rootReducer, applyMiddleware(...middleWares));

export default store;
