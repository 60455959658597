import { SyntheticEvent, useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from '@mui/icons-material';
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  CssBaseline,
  Paper,
  Button,
} from '@mui/material';
import { AppButton, AppIconButton, AppAlert, AppForm, AppLink } from '../../../components';
import { useAppForm, SHARED_CONTROL_PROPS, eventPreventDefault } from '../../../utils/form';

import { connect, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import * as auth from "../../../store/Actions/Auth.action";


import { toastMessage } from '../../../store/Actions/Toast.action';
import axios from 'axios';


const VALIDATE_FORM_SIGNUP = {
  phone: {
    type: 'string',
    format: {
      pattern: '^$|[0-9]+', // Note: We have to allow empty in the pattern
      message: 'should contain numbers',
    },
    length: {
      is: 10,
      message: 'must be exactly 10 digits',
    },
  },  
  phoneOtp: {
    type: 'string',
    presence: { allowEmpty: false },
  },  
  password: {
    presence: true,
    length: {
      minimum: 8,
      maximum: 32,
      message: 'must be between 8 and 32 characters',
    },
  },
  confirm_pass: {
    presence: true,
    length: {
      minimum: 8,
      maximum: 32,
      message: 'must be between 8 and 32 characters',
    },
  },
};



interface FormStateValues {
  phone: string;
  password: string;
  confirm_pass: string;
  phoneOtp?: string;
  token?: string;
}

/**
 * Renders "Signup" view
 * url: /auth/signup
 * @page Signup
 */
interface RouteParams extends Record<string, string | undefined> {
  referenceId?: string; // Make projectId optional
}
const RecoveryPasswordView = ({ message, signupSubmit, errors, agentRegistered }: {
  message:string, signupSubmit: Function, errors: any, agentRegistered: boolean,
}) => {
  const { referenceId } = useParams<RouteParams>();
  const userDispatch = useDispatch();
  const navigate = useNavigate();

  const [validationSchema, setValidationSchema] = useState<any>({
    ...VALIDATE_FORM_SIGNUP,
  });
  const { formState, onFieldChange, fieldGetError, fieldHasError, isFormValid ,resetFormValues} = useAppForm({
    validationSchema: validationSchema, // the state value, so could be changed in time
    initialValues: {
      phone: '',
      password: '',
      countryCode: 'PH',
      confirm_pass: '',
      phoneOtp: '',
      token:''

    } as FormStateValues,
  });

  const [error, setError] = useState<string>();
  const [token, setToken] = useState<string>();
  const values = formState.values as FormStateValues; // Typed alias to formState.values as the "Source of Truth"

  const handleFormSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if(token !== '')
      values.token = token;
    //signupSubmit(values);

    confirmOtp();
  }
  const handleCloseError = useCallback(() => setError(undefined), []);

  const sendOtp = () => {
    setToken('');    
    if(values.phone.length === 10){
      // Make the POST request using Axios
    const url  = process.env.REACT_APP_SERVER_URL + '/user/password/forgot';    
    
    // Define the data you want to send in the POST request
    const postData = {"phone":values.phone}    
    var temp = {...postData}
    temp.phone = `+63${temp.phone}`    
    // Make the POST request using Axios
    axios.post(url, temp).then(response => {
      console.log('Response:', response);
      if(response.data.token !== undefined){
        setToken(response.data.token);
      } else{
        setError(response.data.error);
        console.error('Error:', response.data.error);
        userDispatch(toastMessage({text : response.data.error, type: 'error', head: 'Error'}));
      }
    }).catch(error => {
        // Handle errors
        console.error('Error:', error.response.data.error);
        userDispatch(toastMessage({text : error.response.data.error, type: 'error', head: 'Error'}));

    });
    }
  }

  const confirmOtp = () => {
    if(values.phone.length === 10){
      // Make the POST request using Axios
    const url  = process.env.REACT_APP_SERVER_URL + '/user/password/forgot/reset';    
    
    // Define the data you want to send in the POST request
    const postData = {"country":"+63","phone":values.phone,"password":values.password,"confirm_pass":values.confirm_pass,"token":token,"expiredIn":300,"otp":values.phoneOtp}
    var temp = {...postData}
    temp.phone = `${temp.country}${temp.phone}`
    // Make the POST request using Axios
    axios.post(url, temp).then(response => {
      console.log('Response:', response);
      if(response.status === 200){
        userDispatch(toastMessage({text : 'Password changed successfully', type: 'success', head: 'Success'}));
        resetFormValues();
      } else{
        console.error('Error:', response.data.error);
        userDispatch(toastMessage({text : response.data.error, type: 'error', head: 'Error'}));
      }
    }).catch(error => {
        // Handle errors
        console.error('Error:', error);
    });
    }
  }

  useEffect(() => {    
    if (errors?.error) {
      console.log('hey error');
      userDispatch(toastMessage({text : errors?.error, type: 'error', head: 'Error'}));
    }
  }, [errors])

  useEffect(() => {  
    if (agentRegistered) {
      resetFormValues();
      userDispatch(toastMessage({text : 'Agent registered successfully', type: 'success', head: 'Success'}));
    }
  }, [agentRegistered])


  
  

  return (

    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <AppForm onSubmit={handleFormSubmit}>
          <Card style={{ height: '100vh' }}
            sx={{
              p: 8,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            {/* <CardHeader title="Sign Up" /> */}
            <h4
              style={{ fontSize: '24px', color: '#0c2556', marginTop: '0px', marginBottom: '10px', marginLeft: '16px' }}
            >Forgot Password</h4>
            <p
              style={{ fontSize: '14px', color: '#0c2556', marginTop: '0px', marginBottom: '10px', marginLeft: '16px' }}
            >Recover your password.</p>
            <CardContent>
              
              <Grid container spacing={2}>

                <Grid item xs={12} sm={12}>
                  <TextField
                    sx={{ mt: 0 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          (+63) -
                        </InputAdornment>
                      ),
                    }}
                    label="Phone*"
                    name="phone"
                    size="small"
                    value={values.phone}
                    error={fieldHasError('phone')}
                    helperText={fieldGetError('phone') || ' '}
                    onChange={onFieldChange}
                    onKeyUp={sendOtp}
                    {...SHARED_CONTROL_PROPS}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    sx={{ mt: 0 }}

                    type='password'
                    label="Password*"
                    name="password"
                    size="small"
                    value={values.password}
                    error={fieldHasError('password')}
                    helperText={fieldGetError('password') || ' '}
                    onChange={onFieldChange}
                    {...SHARED_CONTROL_PROPS}

                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    sx={{ mt: 0 }}

                    type='password'
                    label="Confirm Password*"
                    name="confirm_pass"
                    size="small"
                    value={values.confirm_pass}
                    error={fieldHasError('confirm_pass')}
                    helperText={fieldGetError('confirm_pass') || ' '}
                    onChange={onFieldChange}
                    {...SHARED_CONTROL_PROPS}

                  />
                </Grid>

              </Grid>
              {values.phone.length == 10 && token !== '' && <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    sx={{ mt: 0 }}
                    type='text'
                    label="otp"
                    name="phoneOtp"
                    size="small"
                    value={values.phoneOtp}
                    error={fieldHasError('phoneOtp')}
                    helperText={fieldGetError('phoneOtp') || ' '}
                    onChange={onFieldChange}
                    {...SHARED_CONTROL_PROPS}
                  />
                </Grid>                  
              </Grid>}

              {message ? (
                <AppAlert severity="error" onClose={handleCloseError}>
                  {message}
                </AppAlert>
              ) : null}

              <Grid container justifyContent="center" alignItems="center">
                <AppButton fullWidth type="submit"
                  disabled={!(isFormValid())}
                  sx={{
                    backgroundColor: '#F3722C',
                    color: '#fff',
                    m: 0,
                    boxShadow: 0,
                    '&:hover': {
                      backgroundColor: '#F3722C !important',
                      boxShadow: 0,
                    },
                  }}
                >
                  Set Password
                </AppButton>
              </Grid>



            </CardContent>
          </Card>
        </AppForm>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{

          backgroundImage: 'url(/img/login-right-img-1.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  errors: state.auth.errors,
  agentRegistered: state.auth.agentRegistered,
  message: state.toast.text
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  signupSubmit: (data: Dispatch<any>) => auth.signupSubmit(data),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPasswordView);


