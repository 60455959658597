import {
  LOGIN,
  REFRESH,
  LOGOUT,
  SIGNUP,
} from '../Constants';
import { MakeApiRequest } from '../Http';

export const loginSubmit = (data: any) => {
  return MakeApiRequest({
    url: 'signin/agent',
    method: 'POST',
    data: data,
    successMsg: true,
    errorMsg: false,
    nextActionType: LOGIN,
  });
};

export const signupSubmit = (data: any) => {
  return MakeApiRequest({
    url: 'signup/agent/register',
    method: 'POST',
    data: signupData(data),
    successMsg: true,
    errorMsg: false,
    nextActionType: SIGNUP,
  });
};

const signupData = (data: any) => {
  return {
    ...data,
    userName: `${data.firstName} ${data.lastName}`,
    phone: '+63' + data.phone,
  }


}

export const refreshTokenValid = () => ({
  type: REFRESH,
});

export const logout = () => ({
  type: LOGOUT,
});
