import { AppThemeProvider } from './theme';
import { ErrorBoundary } from './components';
import Routes from './routes';
import { Provider } from 'react-redux'
import store from './store/store'


/**
 * Root Application Component
 * @component MainApp
 */
const MainApp = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary name="App">
        <AppThemeProvider>
          <Routes />
        </AppThemeProvider>
      </ErrorBoundary>
    </Provider>
  );
};

export default MainApp;
