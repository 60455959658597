import { SETTLEMENTS, AGENTSETTLEMENTS } from '../Constants';

const initialState = {
  settlementsList: null,
  errors: null,
  loading: false,
  agentsettlementsList: null,
  approval: false,
  betsTotal: null,
  payoutTotal: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SETTLEMENTS.PENDING:
      return { ...state, errors: null, loading: true };
    case SETTLEMENTS.SUCCESS:
      return {
        ...state,
        settlementsList: action.payload.body,
        loading: false,
        betsTotal: getBetTotal(action.payload.body.details),
        payoutTotal: getPayoutTotal(action.payload.body.details),
      };

    case SETTLEMENTS.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case AGENTSETTLEMENTS.PENDING:
      return { ...state, errors: null, loading: true };

    case AGENTSETTLEMENTS.SUCCESS:
      let agentSummary = [];
      if (action.payload.body.data != null && action.payload.body.data.length > 0) {
        agentSummary = Object.entries(action.payload.body.data).map((value: any) => {
          const secondObject = value[1];
          const fromDate = new Date(secondObject.from_date).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          });
          const toDate = new Date(secondObject.to_date).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          });

          return {
            id: parseInt(value[0]) + 1,
            ...secondObject,
            from_date: fromDate,
            to_date: toDate,
          };
        });
      }
      return {
        ...state,
        agentsettlementsList: agentSummary,
        loading: false,
        approval: true,
      };

    case AGENTSETTLEMENTS.FAILURE:
      return { ...state, errors: 'no data found', loading: false, approval: false };

    default:
      return { ...state };
  }
};

function getBetTotal(records: any) {
  return records.reduce((total: number, { bet }: { bet: number }) => total + (parseFloat(bet) || 0), 0);
}
function getPayoutTotal(records: any) {
  return records.reduce((total: number, { payout }: { payout: number }) => total + (parseFloat(payout) || 0), 0);
}

export default reducer;
