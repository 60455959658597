import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import { Link} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Button, Grid, TextField, Card, CardHeader, CardContent, InputAdornment } from '@mui/material';
import { AppButton, AppLink, AppIconButton, AppAlert, AppForm } from '../../../components';
import { useAppForm, SHARED_CONTROL_PROPS, eventPreventDefault } from '../../../utils/form';
import Paper from '@mui/material/Paper';
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as auth from "../../../store/Actions/Auth.action";

const VALIDATE_FORM_LOGIN_EMAIL = {
  phone: {
    presence: true,
  },
  password: {
    presence: true,
    length: {
      minimum: 8,
      maximum: 32,
      message: 'must be between 8 and 32 characters',
    },
  },
};

interface FormStateValues {
  phone: string;
  password: string;
}

/**
 * Renders "Login with Email" view for Login flow
 * url: /auth/login/email
 * @page LoginEmail
 */
const LoginEmailView = ({isLoggedIn, loginSubmit, errors, message}: {isLoggedIn: boolean, loginSubmit: Function, errors:any, message:string}) => {
  const navigate = useNavigate();
  const { formState, onFieldChange, fieldGetError, fieldHasError, isFormValid } = useAppForm({
    validationSchema: VALIDATE_FORM_LOGIN_EMAIL,
    initialValues: { phone: '', password: '' } as FormStateValues,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string>();
  const values = formState.values as FormStateValues; // Typed alias to formState.values as the "Source of Truth"

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  const handleFormSubmit = (event: SyntheticEvent) => {
      event.preventDefault();
      loginSubmit(values);
      if(errors?.error){
        setError(errors.error);
      }
};

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn]);

  const handleCloseError = useCallback(() => setError(undefined), []);

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5}   component={Paper} elevation={6} square>
          <AppForm onSubmit={handleFormSubmit}>
            <Card   style={{ height: '100vh' }}
            sx={{
              p: 8,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
              {/* <CardHeader title="Login with Email" /> */}
              <h4
               style={{fontSize:'24px', color:'#0c2556', marginTop:'0px', marginBottom:'10px', marginLeft:'16px'}}
              >Login</h4>
              <p
               style={{fontSize:'14px', color:'#0c2556', marginTop:'0px', marginBottom:'10px', marginLeft:'16px'}}
              >Welcome back! Login to your account.</p>
              <CardContent>
                <TextField
                  sx={{mt:0}}
                  required
                  label="Username"
                  name="phone"
                  size="small"
                  value={values.phone}
                  error={fieldHasError('phone')}
                  helperText={fieldGetError('phone') || ' '}
                  onChange={onFieldChange}
                  {...SHARED_CONTROL_PROPS}
                />
                <TextField
                  sx={{mt:0}}
                  required
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  name="password"
                  size="small"
                  value={values.password}
                  error={fieldHasError('password')}
                  helperText={fieldGetError('password') || ' '}
                  onChange={onFieldChange}
                  className='passwordinput'
                  {...SHARED_CONTROL_PROPS}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AppIconButton
                          aria-label="toggle password visibility"
                          icon={showPassword ? 'visibilityon' : 'visibilityoff'}
                          title={showPassword ? 'Hide Password' : 'Show Password'}
                          onClick={handleShowPasswordClick}
                          onMouseDown={eventPreventDefault}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors ? (
                  <AppAlert severity="error" onClose={handleCloseError}>
                    {errors.error}
                  </AppAlert>
                ) : null}
                <Grid container justifyContent="center" alignItems="center">
                  <Button variant="text" fullWidth color="inherit" component={AppLink} to="/auth/recovery/password"
                  style={{textTransform:'inherit'}}
                  sx={{
                    justifyContent:'end',
                    backgroundColor:'none',
                    display:'flex',
                    p:0,
                    fontWeight:'400',
                    texttransform:'lowercase !important',
                    '&:hover': {
                      backgroundColor:'inherit !important',
                      boxShadow: 0,
                    },
                  }}>
                    Forgot password
                  </Button>
                  <AppButton type="submit"  fullWidth 
                   style={{textTransform:'capitalize'}}
                  sx={{
                    backgroundColor:'#F3722C',
                    color:'#fff',
                    mt:2,
                    boxShadow: 0,
                    '&:hover': {
                      backgroundColor:'#F3722C !important',
                      boxShadow: 0,
                    },
                  }}>
                    Login
                  </AppButton>
                  
                </Grid>
                <Grid container justifyContent="center" alignItems="center"
                  sx={{
                    justifyContent:'flex-start',
                    pt: 3,
                    
                  }}>
                    {/* Don't have an account ?  
                  <Link href="/auth/signup"
                   sx={{textDecoration:'none', pl:.5,}}
                  > Sign Up
                  </Link> */}
                </Grid>
              </CardContent>
            </Card>
          </AppForm>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{

            backgroundImage: 'url(/img/login-right-img-1.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        
    </Grid>
    
  );
};

const mapStateToProps = (state: any) => ({
  isLoggedIn: state.auth.isLoggedIn,
  errors: state.auth.errors,
  loading: state.auth.loading,
  message: state.toast.text
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  loginSubmit: (data: Dispatch<any>) => auth.loginSubmit(data),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginEmailView);
