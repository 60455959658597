import axios from 'axios';
import store from './store';
import { TOAST_MESSAGE } from './Constants';

const urlList: any = {
  login: process.env.REACT_APP_SERVER_URL, //'https://zwhrwwps22tcpnlu4u7vlzv3qi0lgyvy.lambda-url.ap-southeast-1.on.aws/',
  report: process.env.REACT_APP_REPORT_URL, //'https://wr3tz22xbreq5dbabiwd2iqtyq0hbwqe.lambda-url.ap-southeast-1.on.aws/', //process.env.REACT_APP_LOGIN_API_URL,
  postsettlement: process.env.REACT_APP_POST_URL, //'https://3yakaekiikmpb4kaa55n3jtqsa0egcgm.lambda-url.ap-southeast-1.on.aws/', //process.env.REACT_APP_LOGIN_API_URL,
};

export const MakeApiRequest = ({
  server = 'login',
  url,
  method = 'GET',
  data,
  params,
  headers,
  successMsg = false,
  errorMsg = false,
  nextActionType,
}: {
  server?: string;
  url: string;
  method?: string;
  data?: any;
  params?: any;
  headers?: any;
  successMsg?: boolean;
  errorMsg?: boolean;
  nextActionType?: any;
}) => {
  let baseURL = urlList[server];
  const storeData: any = store.getState();
  let token = '';
  if (storeData && storeData.auth && storeData.auth?.token) {
    token = 'Bearer ' + storeData.auth.token.token;
  }
  const headersData: any = {
    'Content-type': 'application/json',
    Authorization: token,
  };

  if (headers) {
    Object.entries(headers).forEach((item: any) => {
      headersData[item[0]] = item[1];
    });
  }
  if (headersData['Content-type'] === 'application/json') {
    data = JSON.stringify(data);
  }

  const options = {
    baseURL: baseURL,
    method: method.trim().toLowerCase().toUpperCase(),
    url: url.trim(),
    data,
    headers: headersData,
    params: params,
    withCredentials: true,
  };

  store.dispatch({ type: nextActionType.PENDING, payload: '' });
  axios(options)
    .then((res: any) => {
      store.dispatch({ type: nextActionType.SUCCESS, payload: res.data });
    })
    .catch((err) => {
      try {
        let res = '';
        if (err?.response?.status >= 400) {
          res = err.response.data.error;
          if (1) {
            store.dispatch({
              type: TOAST_MESSAGE,
              payload: { type: 'error', head: 'Error', text: res },
            });
          }
        }
      } catch (error) {
        console.log(error);
        console.log('Error in response');
      }
      store.dispatch({
        type: nextActionType.FAILURE,
        payload: err?.response?.data ? err.response.data : '',
      });
    });
};
