import { WALLET } from '../Constants';

const initialState = {
  walletList: null,
  errors: null,
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case WALLET.PENDING:
      return { ...state, errors: null, loading: true };
    case WALLET.SUCCESS:
      return {
        ...state,
        walletList: action.payload[0],
        loading: false,
      };
    case WALLET.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    default:
      return {...state};
  }
};

export default reducer;
