import { SyntheticEvent, useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link, Padding } from '@mui/icons-material';
import { Grid, TextField, Card, CardContent, InputAdornment, CssBaseline, Paper, Button, styled } from '@mui/material';
import { AppButton, AppIconButton, AppAlert, AppForm, AppLink } from '../../../components';
import { useAppForm, SHARED_CONTROL_PROPS, eventPreventDefault } from '../../../utils/form';

import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import * as auth from '../../../store/Actions/Auth.action';

import { toastMessage } from '../../../store/Actions/Toast.action';
import axios from 'axios';

const TextDiv = styled('div')(({ theme }) => ({
  padding: '64px',

  '@media (max-width: 660px)': {
    padding: '20px !important',
  },
}));

const VALIDATE_FORM_SIGNUP = {
  phone: {
    type: 'string',
    format: {
      pattern: '^$|[0-9]+', // Note: We have to allow empty in the pattern
      message: 'should contain numbers',
    },
    length: {
      is: 10,
      message: 'must be exactly 10 digits',
    },
  },
  firstName: {
    type: 'string',
    presence: { allowEmpty: false },
    format: {
      pattern: '^[A-Za-z0-9-]+$',
      message: 'should contain only alphabets',
    },
  },
  phoneOtp: {
    type: 'string',
    presence: { allowEmpty: false },
  },
  lastName: {
    type: 'string',
    presence: { allowEmpty: false },
    format: {
      pattern: '^[A-Za-z0-9-]+$',
      message: 'should contain only alphabets',
    },
  },
  password: {
    presence: true,
    length: {
      minimum: 8,
      maximum: 32,
      message: 'must be between 8 and 32 characters',
    },
  },
  confirm_pass: {
    presence: true,
    length: {
      minimum: 8,
      maximum: 32,
      message: 'must be between 8 and 32 characters',
    },
  },
};

interface FormStateValues {
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
  confirm_pass: string;
  agentId?: string;
  phoneOtp?: string;
  token?: string;
}

/**
 * Renders "Signup" view
 * url: /auth/signup
 * @page Signup
 */
interface RouteParams extends Record<string, string | undefined> {
  referenceId?: string; // Make projectId optional
}
const OtpSignupView = ({
  signupSubmit,
  errors,
  agentRegistered,
  signupuser,
}: {
  signupSubmit: Function;
  errors: any;
  agentRegistered: boolean;
  signupuser: any;
}) => {
  const { referenceId } = useParams<RouteParams>();
  const userDispatch = useDispatch();
  const navigate = useNavigate();

  const [validationSchema, setValidationSchema] = useState<any>({
    ...VALIDATE_FORM_SIGNUP,
  });
  const { formState, onFieldChange, fieldGetError, fieldHasError, isFormValid, resetFormValues } = useAppForm({
    validationSchema: validationSchema, // the state value, so could be changed in time
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      password: '',
      confirm_pass: '',
      countryCode: 'PH',
      agentId: referenceId,
      phoneOtp: '',
      token: '',
    } as FormStateValues,
  });

  const [error, setError] = useState<string>();
  const [token, setToken] = useState('');
  const [enableOtp, setEnableOtp] = useState(false);
  const values = formState.values as FormStateValues; // Typed alias to formState.values as the "Source of Truth"

  const handleFormSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (token !== '') values.token = token;
    signupSubmit(values);
  };
  const handleCloseError = useCallback(() => setError(undefined), []);

  const sendOtp = () => {
    setToken('');
    setEnableOtp(true);
    if (values.phone.length === 10) {
      // Make the POST request using Axios
      const url = process.env.REACT_APP_SERVER_URL + '/signup/phone/otp';

      // Define the data you want to send in the POST request
      const postData = { phone: '+63' + values.phone };

      // Make the POST request using Axios
      axios
        .post(url, postData)
        .then((response) => {
          if (response.data.token !== undefined) {
            setToken(response.data.token);
            setTimeout(() => {
              setEnableOtp(false);
            }, 10000);
          } else {
            setError(response.data.error);
          }
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
        });
    }
  };
  useEffect(() => {
    if (errors?.error) {
      console.log('hey error');
      userDispatch(toastMessage({ text: errors?.error, type: 'error', head: 'Error' }));
    }
  }, [errors]);

  useEffect(() => {
    if (agentRegistered) {
      resetFormValues();
      userDispatch(toastMessage({ text: 'Agent registered successfully', type: 'success', head: 'Success' }));
      if (signupuser && signupuser.agentUserType === 'AGENTPLAYER') {
        setTimeout(() => {
          window.location.href = 'https://arionplay.com/';
        }, 5000);
      }
    }
  }, [agentRegistered]);

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <AppForm onSubmit={handleFormSubmit}>
          <Card
            style={{ height: '100vh', padding: '0px' }}
            sx={{
              p: 8,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <TextDiv>
              {/* <CardHeader title="Sign Up" /> */}
              <h4
                style={{
                  fontSize: '24px',
                  color: '#0c2556',
                  marginTop: '0px',
                  marginBottom: '10px',
                  marginLeft: '16px',
                }}
              >
                Register
              </h4>
              <p
                style={{
                  fontSize: '14px',
                  color: '#0c2556',
                  marginTop: '0px',
                  marginBottom: '10px',
                  marginLeft: '16px',
                }}
              >
                Create an account with Agent using otp.
              </p>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      sx={{ mt: 0 }}
                      label="First Name*"
                      name="firstName"
                      size="small"
                      value={values.firstName}
                      error={fieldHasError('firstName')}
                      helperText={fieldGetError('firstName') || ' '}
                      onChange={onFieldChange}
                      {...SHARED_CONTROL_PROPS}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      sx={{ mt: 0 }}
                      label="Last Name*"
                      name="lastName"
                      size="small"
                      value={values.lastName}
                      error={fieldHasError('lastName')}
                      helperText={fieldGetError('lastName') || ' '}
                      onChange={onFieldChange}
                      {...SHARED_CONTROL_PROPS}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={8} sm={9}>
                    <TextField
                      sx={{ mt: 0 }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">(+63) -</InputAdornment>,
                      }}
                      label="Phone*"
                      name="phone"
                      size="small"
                      value={values.phone}
                      error={fieldHasError('phone')}
                      helperText={fieldGetError('phone') || ' '}
                      onChange={onFieldChange}
                      {...SHARED_CONTROL_PROPS}
                    />
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <AppButton
                      type="button"
                      onClick={sendOtp}
                      disabled={values.phone.length !== 10 || enableOtp}
                      sx={{
                        backgroundColor: '#F3722C',
                        color: '#fff',
                        m: 0,
                        boxShadow: 0,
                        height: '40px',
                        width: '100%',
                        whiteSpace: 'nowrap',

                        '&:hover': {
                          backgroundColor: '#F3722C !important',
                          boxShadow: 0,
                        },
                      }}
                    >
                      Get OTP
                    </AppButton>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      sx={{ mt: 0 }}
                      type="password"
                      label="Password*"
                      name="password"
                      size="small"
                      value={values.password}
                      error={fieldHasError('password')}
                      helperText={fieldGetError('password') || ' '}
                      onChange={onFieldChange}
                      {...SHARED_CONTROL_PROPS}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      sx={{ mt: 0 }}
                      type="password"
                      label="Confirm Password*"
                      name="confirm_pass"
                      size="small"
                      value={values.confirm_pass}
                      error={fieldHasError('confirm_pass')}
                      helperText={fieldGetError('confirm_pass') || ' '}
                      onChange={onFieldChange}
                      {...SHARED_CONTROL_PROPS}
                    />
                  </Grid>
                </Grid>
                {values.phone.length == 10 && token !== '' && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        sx={{ mt: 0 }}
                        type="text"
                        label="otp"
                        name="phoneOtp"
                        size="small"
                        value={values.phoneOtp}
                        error={fieldHasError('phoneOtp')}
                        helperText={fieldGetError('phoneOtp') || ' '}
                        onChange={onFieldChange}
                        {...SHARED_CONTROL_PROPS}
                      />
                    </Grid>
                  </Grid>
                )}

                {error ? (
                  <AppAlert severity="error" onClose={handleCloseError}>
                    {error}
                  </AppAlert>
                ) : null}

                {/* {signupuser && signupuser.agentUserType === 'AGENTPLAYER' ? (<a href='https://www.fairplay.ph/'> click here to log in as a player</a>) : null} */}

                <Grid container justifyContent="center" alignItems="center">
                  <AppButton
                    fullWidth
                    type="submit"
                    disabled={!isFormValid()}
                    sx={{
                      backgroundColor: '#F3722C',
                      color: '#fff',
                      m: 0,
                      boxShadow: 0,
                      '&:hover': {
                        backgroundColor: '#F3722C !important',
                        boxShadow: 0,
                      },
                    }}
                  >
                    Confirm and Sign Up
                  </AppButton>
                </Grid>
                <span className="otpErrorMessage">
                  {enableOtp && 'OTP not received. Please attempt again in 20 seconds.'}
                </span>
              </CardContent>
            </TextDiv>
          </Card>
        </AppForm>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/img/login-right-img-1.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  errors: state.auth.errors,
  agentRegistered: state.auth.agentRegistered,
  signupuser: state.auth.signupuser,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  signupSubmit: (data: Dispatch<any>) => auth.signupSubmit(data),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtpSignupView);
