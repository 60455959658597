import { useCallback } from 'react';

/**
 * Returns event handler to toggle Dark/Light modes
 * @returns {function} calling this event toggles dark/light mode
 */
export function useEventSwitchDarkMode() {

  return useCallback(() => {
    
  }, []);
}
