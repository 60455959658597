import { combineReducers } from 'redux';
import auth from './auth.reducer';
import toast from './toast.reducer';
import users from './users.reducer';
import deposits from './deposits.reducer';
import wallet from './wallet.reducer';
import bets from './bets.reducer';
import settlements from './settlements.reducer';

const reducerHash = {
  auth,
  toast,
  users,
  deposits,
  wallet,
  bets,
  settlements,
};

export default combineReducers(reducerHash);
