import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateLayout } from '../layout';
import { NotFoundView } from '../views';
import { AppLoading } from '../components';

const AboutView = lazy(() => import('../views/About'));
const WelcomeView = lazy(() => import('../views/Welcome'));
const DetailedDashboard = lazy(() => import('../views/DetailedDashboard'));
const UsersList = lazy(() => import('../views/Users'));
const Logout = lazy(() => import('../views/Logout'));
const DepositsList = lazy(() => import('../views/Deposits'));
const ApprovalDepositsList = lazy(() => import('../views/Approvaldeposits'));
const RequestDeposits = lazy(() => import('../views/Requestdeposits'));
const RequestWithdraws = lazy(() => import('../views/Requestwithdraws'));
const ApproveWithdraws = lazy(() => import('../views/Approvewithdraws'));
const ApprovalUsersList = lazy(() => import('../views/Approvalusers/ApprovalUsersList'));
const BetsList = lazy(() => import('../views/Bets'));
const SettlementsList = lazy(() => import('../views/Settlements'));
const AgentSettlements = lazy(() => import('../views/Agentsettlements'));
const ChangePassword = lazy(() => import('../views/Changepassword'));
const HistoricalReports = lazy(() => import('../views/HistoricalReports'));
const CommissionByPeriod = lazy(() => import('../views/CommissionByPeriod'));
const CommissionSettlement = lazy(() => import('../views/CommissionSettlement'));
const Hierarchy = lazy(() => import('../views/Hierarchy'));
const UsersHierarchy = lazy(() => import('../views/UsersHierarchy'));

/**
 * List of routes available  for authenticated users
 * Also renders the "Private Layout" composition
 * @routes PrivateRoutes
 */
const PrivateRoutes = () => {
  return (
    <PrivateLayout>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <WelcomeView />
            </Suspense>
          }
        />
        <Route
          // This fixes other tabs with unfinished auth flow
          path="auth/*"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="users/:type"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <UsersList />
            </Suspense>
          }
        />
        <Route
          path="detaileddashboard"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <DetailedDashboard />
            </Suspense>
          }
        />
        <Route
          path="hierarchy"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <Hierarchy />
            </Suspense>
          }
        />
        <Route
          path="usershierarchyexcel"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <UsersHierarchy />
            </Suspense>
          }
        />
        <Route
          path="welcomeview"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <WelcomeView />
            </Suspense>
          }
        />
        <Route
          path="deposits"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <DepositsList />
            </Suspense>
          }
        />
        <Route
          path="approvaldeposits"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <ApprovalDepositsList />
            </Suspense>
          }
        />
        <Route
          path="approvalusers/:type"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <ApprovalUsersList />
            </Suspense>
          }
        />
        <Route
          path="requestdeposits"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <RequestDeposits />
            </Suspense>
          }
        />
        <Route
          path="requestwithdraws"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <RequestWithdraws />
            </Suspense>
          }
        />
        <Route
          path="approvewithdraws"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <ApproveWithdraws />
            </Suspense>
          }
        />
        <Route
          path="about"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <AboutView />
            </Suspense>
          }
        />
        <Route
          path="bets"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <BetsList />
            </Suspense>
          }
        />
        <Route
          path="settlements"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <SettlementsList />
            </Suspense>
          }
        />
        <Route
          path="commissionbyperiod"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <CommissionByPeriod />
            </Suspense>
          }
        />
        <Route
          path="commissionsettlement"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <CommissionSettlement />
            </Suspense>
          }
        />
        <Route
          path="agentsettlement"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <AgentSettlements />
            </Suspense>
          }
        />
        <Route
          path="historicalreports"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <HistoricalReports />
            </Suspense>
          }
        />
        <Route
          path="logout"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <Logout />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <NotFoundView />
            </Suspense>
          }
        />
        <Route
          path="changepassword"
          element={
            <Suspense
              fallback={
                <>
                  <AppLoading />
                </>
              }
            >
              <ChangePassword />
            </Suspense>
          }
        />
      </Routes>
    </PrivateLayout>
  );
};

export default PrivateRoutes;
