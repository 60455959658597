export const asyncAction = (type: string) => ({
  PENDING: `${type}_PENDING`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
});

export const API_CALL = 'API_CALL';
export const LOGIN = asyncAction('LOGIN');
export const SIGNUP = asyncAction('SIGNUP');
export const LOGOUT = 'LOGOUT';
export const REFRESH = 'REFRESH';
export const REFRESHFORM = 'REFRESHFORM';


//Toast
export const TOAST_MESSAGE = 'TOAST_MESSAGE';
export const CLEAR_TOAST = 'CLEAR_TOAST';

//Users
export const USERS = asyncAction('USERS');
export const APPROVEUSERS = asyncAction('APPROVEUSERS');
export const DASHBOARDLIST = asyncAction('DASHBOARDLIST');

//Deposits
export const DEPOSITS = asyncAction('DEPOSITS');
export const APPROVED_DEPOSITS = asyncAction('APPROVED_DEPOSITS');
export const WITHDRAWREQUEST = asyncAction('WITHDRAWREQUEST');
export const APPROVED_WITHDRAWS = asyncAction('APPROVED_WITHDRAWS');

//Wallet
export const WALLET = asyncAction('WALLET');

//Bets
export const BETS = asyncAction('BETS');

//SETTLEMENTS
export const SETTLEMENTS = asyncAction('SETTLEMENTS');
export const AGENTSETTLEMENTS = asyncAction('AGENTSETTLEMENTS');
export const WITHDRAWALS = asyncAction('WITHDRAWALS');
export const APPROVEDWITHDRAWALS = asyncAction('APPROVEDWITHDRAWALS');
