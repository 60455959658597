/**
 * Layout configuration
 */

/**
 * SideBar configuration
 */
export const SIDE_BAR_MOBILE_ANCHOR = 'right'; // 'right';
export const SIDE_BAR_DESKTOP_ANCHOR = 'left'; // 'right';
export const SIDE_BAR_WIDTH = '250px';

/**
 * TopBar configuration
 */
export const TOP_BAR_MOBILE_HEIGHT = '56px';
export const TOP_BAR_DESKTOP_HEIGHT = '64px';

/**
 * BottomBar configuration
 */
export const BOTTOM_BAR_DESKTOP_VISIBLE = false; // true;
