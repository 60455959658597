import { LOGIN, LOGOUT, REFRESH, SIGNUP } from '../Constants';

const initialState = {
  user: null,
  signupuser: null,
  token: null,
  isLoggedIn: null,
  errors: null,
  loading: false,
  isRefreshTokenChecked: null,
};

const storeSessionData = async (response: any) => {
  localStorage.setItem('user', JSON.stringify(response.user));
  localStorage.setItem('token', JSON.stringify(response.token));
};

const fetchSessionData = () => {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  if (user !== null && user !== undefined && user !== '' && token !== null && token !== undefined && token !== '') {
    return { user: JSON.parse(user), token: JSON.parse(token) };
  }
  return false;
};

const clearSessionData = async () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN.PENDING:
      return { ...state, errors: null, loading: true };
    case LOGIN.SUCCESS:
      const res = action.payload;
      const user = {
        firstName: res.firstName,
        lastName: res.lastName,
        userId: res.userId,
        username: res.username,
        selectedWalletId: res.selectedWalletId,
        roles: res.roles,
        phone: res.phone,

        egamesOwnerPercentage: res.egamesOwnerPercentage,
        egamesMasterAgentPercentage: res.egamesMasterAgentPercentage,
        egamesGoldenAgentPercentage: res.egamesGoldenAgentPercentage,

        sportsOwnerPercentage: res.sportsOwnerPercentage,
        sportsMasterAgentPercentage: res.sportsMasterAgentPercentage,
        sportsGoldenAgentPercentage: res.sportsGoldenAgentPercentage
      };
      const token = { token: res.token };
      storeSessionData({ user, token });
      return {
        ...state,
        user: user,
        isLoggedIn: true,
        token: token,
        loading: false,
      };
    case LOGIN.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case SIGNUP.PENDING:
      return { ...state, errors: null, loading: true, agentRegistered: false };
    case SIGNUP.SUCCESS:
      const response = action.payload;
      const userType = {
        agentUserType: response.agentUserType
      };

      return { ...state, signupuser: userType, errors: null, loading: false, agentRegistered: true };

    case SIGNUP.FAILURE:
      return { ...state, errors: action.payload, loading: false, agentRegistered: false };

    case REFRESH:
      const result: any = fetchSessionData();
      const obj = { user: null, token: null, isLoggedIn: false };
      if (result) {
        obj.user = result.user;
        obj.token = result.token;
        obj.isLoggedIn = true;
      }
      return { ...state, errors: null, ...obj, isRefreshTokenChecked: true, loading: false };

    case LOGOUT:
      clearSessionData();
      return { ...state, errors: null, user: null, token: null, isLoggedIn: false, loading: true };

    default:
      return { ...state };
  }
};

export default reducer;
