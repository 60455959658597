import { TOAST_MESSAGE, CLEAR_TOAST } from '../Constants';

export const toastMessage = ({text,type,head}: {text:any,type:any,head:any}) => {
  return {
    type: TOAST_MESSAGE,
    payload: { text: text ,type:type,head:head}
  }
}


export const clearToasts = () => ({
  type: CLEAR_TOAST,
});

