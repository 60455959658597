import { Alert } from '@mui/material';
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as toast from "../../store/Actions/Toast.action";
import { useEffect } from 'react';
type AlertColor = 'success' | 'info' | 'warning' | 'error';
const Toast = ({text,type,clearToasts} : {text: any,type:AlertColor,clearToasts : Function}) => {
  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
   
        const timeoutId = setTimeout(() => {
          if(!!text)
            {
          clearToasts()
        }
        }, 5000);
    
   

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [clearToasts,text]);
   
  return (
    <div role="presentation" >
      <Alert variant="outlined" severity={type}>
           {text}
      </Alert>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  text: state.toast.text,
  type: state.toast.type,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  clearToasts: async () => dispatch(toast.clearToasts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);

